import React from 'react';

import photo1 from '../../images/top1.png'
import photo2 from '../../images/top2.png'
import photo3 from '../../images/top4.jpg'

function Photo() {
    return (
        <section id="photo">
            <h2>活動風景</h2>
            
            {/*  
            <div class="list">
                <img src="images/sample1.jpg" alt="" />
                <h4>ここの見出しのサイズは、cssフォルダのstyle.cssの「.list h4」で変更できます。</h4>
                <p>左の画像の幅もstyle.cssの「.list img」のwidthの数字で変更できます。</p>
            </div>
            */}
            
            <div class="list">
                <img src={photo1} alt="" /> <img src={photo2} alt="" />
            </div>
            
            <div class="list">
                <img src={photo3} alt="" /> <img src={photo1} alt="" />
            </div>
            
            <div class="list">
                <img src={photo2} alt="" /> <img src={photo3} alt="" />
            </div>
            
            {/*  
            <div class="list">
                <img src={photo2} alt="" />
                <h4>石山大会</h4>
                <p>説明</p>
            </div>
            */}
        </section>
    );
}

export default Photo;