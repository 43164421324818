import React from 'react';

function Contact() {
    return (
        <section id="contact">
            <h2>連絡先</h2>
            <p class="c">2024年度も新規メンバーを募集中です！</p>
            <p class="c">入部希望、練習試合のお問い合わせは以下までお願い致します。</p>
            <p class="c">代表：高田</p>
            <p class="c">xxxxxxxxxx@gmail.com</p>
            <p class="c"><img src="images/sample1.jpg" alt="" width="300" /></p>
        </section>
    );
}

export default Contact;