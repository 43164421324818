import React from 'react';

// チーム紹介
function TeamIntroduction() {
    return (
        <section id="team_introduction">
            <h2>新潟MIT チーム紹介</h2>
            <p>新潟MITは新潟市を中心に活動する草野球チームです。</p>
            
            <p>設立年：</p>
            <p>平均年齢：</p>
            <p>活動詳細：</p>
            <p>こんな人を探してます：</p>
            <p>活動方針：</p>
            <p>部費（ユニフォーム代金等）：</p>
            <p>…等</p>
        </section>
    );
}

export default TeamIntroduction;