import React from 'react';
import { Link as Scroll } from 'react-scroll';

import logo from '../../images/mit_logo.png' 
import backgroundimg from '../../images/top3.png'

function Header() {
    return (
        <header style={styles.header}>
            <h1 id="logo"><a href="index.html"><img src={logo} alt="SAMPLE COMPANY" /></a></h1>
            <nav id="menubar">
                <ul>
                    <li><Scroll to="team_introduction" smooth={true}>チーム紹介</Scroll></li>
                    <li><Scroll to="match_result" smooth={true}>試合結果</Scroll></li>
                    <li><Scroll to="photo" smooth={true}>活動風景</Scroll></li>
                    <li><Scroll to="contact" smooth={true}>連絡先</Scroll></li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;

// ロゴ画像の読み込みにimport必要なため、CSSから移動
const styles = {
    header: {
        background: `url(${backgroundimg}) no-repeat center center / cover`,
    }
}