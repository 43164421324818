import React from 'react';
import "../../css/style.css";

import Header from './Header';
import Contents from './Contents';
import Footer from './Footer';
import ScrollButton from '../../components/ScrollButton';

function Home() {    
    return (
        <div id="TopPage">
            <Header />
            <Contents />
            <Footer />
            <ScrollButton scrollTo="TopPage"/>
        </div>
    );
}

export default Home;