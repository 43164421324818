import React from 'react';

function MatchResult() {
    return (
        <section id="match_result">
            <h2>試合結果</h2>
            
            <h3>2024年度</h3>
            
            <table class="ta1">
                <caption>新潟市軟式野球選手権大会</caption>
                <tr>
                    <th>1回戦</th>
                    <td>10/23予定</td>
                </tr>
            </table>
            
            <table class="ta1">
                <caption>大形地区野球大会</caption>
                <tr>
                    <th>決勝戦</th>
                    <td>VS KNAVE 5-6 負け (延長8回 タイブレーク)</td>
                </tr>
                <tr>
                    <th>準決勝</th>
                    <td>VS SBX 2-1 勝ち</td>
                </tr>
                <tr>
                    <th>3回戦</th>
                    <td></td>
                </tr>
                <tr>
                    <th>2回戦</th>
                    <td></td>
                </tr>
                <tr>
                    <th>1回戦</th>
                    <td></td>
                </tr>
            </table>
            
            <table class="ta1">
                <caption>西区野球連盟 秋季トーナメント</caption>
                <tr>
                    <th>2回戦</th>
                    <td></td>
                </tr>
                <tr>
                    <th>1回戦</th>
                    <td></td>
                </tr>
            </table>
        </section>
    );
}

export default MatchResult;