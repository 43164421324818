import React from 'react';

function Footer(){
    return (
        <footer>
            <p>&copy; 2022 Kohei Kasahara</p>
        </footer>
    );
}

export default Footer;