import { Amplify } from 'aws-amplify'
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './pages/Home/Home';
import MembersHome from './pages/Members/MembersHome';

import '@aws-amplify/ui-react/styles.css';
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/Home" element={<Home/>}/>
          <Route path="/MembersHome" element={<MembersHome/>}/>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
