import React, {useEffect} from 'react';
import { Link as Scroll } from 'react-scroll';
import $ from 'jquery'; 

function ScrollButton(prop){
    useEffect(() => { 
        var to = '#' + prop.scrollTo;
        var scroll = $('#root').children(to).children('.scroll');
        var scrollShow = $('.scroll-show');
            $(scroll).hide();
    	         $(window).scroll(function(){
                if($(this).scrollTop() >= 300) {
                    $(scroll).fadeIn(500).addClass(scrollShow);
                } else {
                    $(scroll).fadeOut(500).removeClass(scrollShow);
                }
            });
     }, [prop.scrollTo]);
    return (
        <p class="scroll"><Scroll to={prop.scrollTo} smooth={true}>↑</Scroll></p>
    );
}

export default ScrollButton;