import React from 'react';
import { Link } from 'react-router-dom';

import TeamIntroduction from './TeamIntroduction';
import MatchResult from './MatchResult';
import Photo from './Photo';
import Contact from './Contact';

function Contents() {
    return (
        <div id="container">
            <div id="contents">
                <TeamIntroduction />
                <MatchResult />
                <Photo />
                <Contact />
                <section id="member">
                
                    <h2>メンバー用</h2>
                    <p class="c">メンバー用サイト</p>
                    <p class="c"><Link to="/MembersHome">こちらから</Link></p>
                
                </section>
            </div>
        </div>
    );
}

export default Contents;