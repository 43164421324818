import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { withAuthenticator, Button } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify'
import ScrollButton from '../../components/ScrollButton';
import awsExports from "../../aws-exports";
Amplify.configure(awsExports);

function MembersHome({ signOut, user }) {
    
    const navigation = useNavigate();
    function signoutButton() {
        signOut();
        navigation("/");
    }

    return (
      // <div id="MembersTopPage" style={styles.container}>
      <div id="MembersTopPage">
        <h1>メンバー専用ページ</h1>
        <br/><p><Link to="/">HPに戻る</Link></p>
        <iframe title="スプレッドシート" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQeo3NEQMxP9Ycz32tMu0NoHWK8MynRZZLnOtMTNeQrDaJvBQxlbgyfjP6zTZ8tg25a-BXhepFo4I9B/pubhtml?widget=true&amp;headers=false"></iframe>
        <iframe title="カレンダー" src="https://calendar.google.com/calendar/embed?src=e490b2a59cde8410497a2973ba5d560df3a48a912931e7d00e5922571c8bcc4e%40group.calendar.google.com&ctz=Asia%2FTokyo"></iframe>
        <br/><br/>
        <Button onClick={signoutButton}>Sign out</Button>
        <ScrollButton scrollTo="MembersTopPage"/>
      </div>
    );
}

export default withAuthenticator(MembersHome, {hideSignUp: true});

// const styles = {
//   container: { width: 380, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
//   todo: {  marginBottom: 15 },
//   input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
//   todoName: { fontSize: 20, fontWeight: 'bold' },
//   todoDescription: { marginBottom: 0 },
//   button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
// }